import { FC, useMemo } from "react";
import MyAccordionCourse from "./MyAccordionCourse.tsx";
import { Course } from "../../types/myCourse.type";
import Button from "../shared/Button.tsx";
import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

interface MyCourseCardProps {
  course: Course;
}

const MyCourseCard: FC<MyCourseCardProps> = ({ course }) => {
  const { price, modules } = course;
  const module = useMemo(() => {
    return (
      modules.find((module) => !module.passed) || modules[modules.length - 1]
    );
  }, [modules]);
  const student = useSelector((state: any) => state.studentReducer.student);
  const start = "https://secure.wayforpay.com/button/b2c2fd7bff3d1";
  const pro = "https://secure.wayforpay.com/button/b7a48d02644dd";

  return (
    <div className="w-full max-w-[1440px] bg-white p-4  border rounded-xl shadow-lg">
      <MyAccordionCourse course={course} />

      <div className="w-full flex justify-center items-center  pt-4">
        <NavLink to={`/lessonsData/${module?.module.id}`}>
          <Button
            classes="lg:!text-base xl:!text-lg animate-pulse"
            title="Почати"
            variant="filled"
          />
        </NavLink>
      </div>
    </div>
  );
};

export default MyCourseCard;
