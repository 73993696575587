import {
  Text,
  Accordion,
  Avatar,
  RingProgress,
  Progress,
  CheckIcon,
} from "@mantine/core";
import { Course } from "../../types/myCourse.type";
import { FC, useEffect, useState } from "react";
import formatString from "../../utils/formatString.ts";
import React from "react";
import useScreenSize from "../../hooks/windowSizeHook.tsx";
import { IconPlayerPlay } from "@tabler/icons-react";
import clsx from "clsx";
import { NavLink } from "react-router-dom";
interface MyAccordionCourseProps {
  course: Course;
}

interface AccordionLabelProps {
  course: Course;
}

const AccordionLabel: FC<AccordionLabelProps> = ({ course }) => {
  const { description, title, image, speaker, modules, duration } = course;
  const [courseProgress, setCourseProgress] = useState(0);
  const { height, width } = useScreenSize();

  const [progressSize, setProgressSize] = useState(220);
  useEffect(() => {
    const passedModules = modules.filter((module) => module.passed);
    const progress = (passedModules.length / modules.length) * 100;
    setCourseProgress(progress);
  }, [course]);

  useEffect(() => {
    switch (true) {
      case width < 1200:
        setProgressSize(180);
        break;
      case width < 1100:
        setProgressSize(120);
        break;
    }
  }, [height, width]);

  return (
    <div className="flex items-center md:items-center lg:flex-row flex-col w-full lg:items-center xl:items-start relative">
      <img
        src={image}
        alt={title}
        className="sm:h-32 sm:w-32 xl:w-64 xl:h-64 lg:w-48 lg:h-48 md:w-48 md:h-48 rounded-md md:mb-4"
      />
      <div className="flex flex-col gap-2 w-full">
        <h1 className="self-center text-center font-semibold text-purple-950 text-xl pt-2 lg:pt-0 md:text-2xl lg:mr-[40px]">
          {title}
        </h1>

        <div className="w-full flex flex-col lg:flex-row lg:justify-between">
          <div className="sm:px-0  md:px-4 pt-2  xl:max-w-xl lg:max-w-sm h-full lg:min-h-[110px] xl:min-h-[170px]">
            {formatString(description).map((str, idx) => (
              <Text
                className="xl:!text-base lg:text-sm text-sm text-gray-800"
                key={idx}
              >
                {str}
              </Text>
            ))}
          </div>
          <div className="w-full hidden lg:flex justify-end items-center">
            <RingProgress
              size={progressSize}
              sections={[{ value: courseProgress, color: "#6D28D9" }]}
              classNames={{
                label: "!text-primary",
                curve: "!text-primary !border-primary !bg-primary",
              }}
              label={
                <Text fw={700} ta="center" size="xl">
                  {`${courseProgress.toFixed(2)}%`}
                </Text>
              }
            />
          </div>
          <div className="w-full lg:hidden flex justify-center items-center py-4">
            <Progress.Root
              className="w-full"
              classNames={{
                section: "!bg-primary",
              }}
              size="xl"
            >
              <Progress.Section value={courseProgress}>
                <Progress.Label>{`${courseProgress.toFixed(
                  2
                )}%`}</Progress.Label>
              </Progress.Section>
            </Progress.Root>
          </div>
        </div>
      </div>
    </div>
  );
};

const MyAccordionCourse: FC<MyAccordionCourseProps> = ({ course }) => {
  const [isOpened, setIsOpened] = useState<string | null>(null);

  return (
    <Accordion
      value={isOpened}
      onChange={setIsOpened}
      classNames={{
        item: `border-[2px]  hover:!border-primary !bg-opacity-[0.2] !transition-all ${
          isOpened
            ? "!bg-third !border-primary !border-b-0"
            : "bg-white! !border-white"
        }`,
        chevron: "!hidden",
        panel: `${isOpened && " bg-white"}`,
        icon: `${isOpened && "fill-white"}`,
        content: "!p-0",
      }}
      variant="contained"
    >
      <Accordion.Item value={course.title} key={course.id}>
        <Accordion.Control className="w-full">
          <AccordionLabel course={course} />
        </Accordion.Control>
        <Accordion.Panel>
          {course.modules.map((module, idx) => (
            <NavLink
              className={"no-underline"}
              to={`/lessonsData/${module.module.id}`}
            >
              <div
                className={clsx(
                  `flex w-full lg:px-4 px-2 items-center border-b group cursor-pointer`,
                  module.passed
                    ? "bg-green-100 hover:bg-green-50"
                    : " hover:bg-gray-200"
                )}
                key={module.id}
              >
                {!module.passed ? (
                  <div className="w-7 h-7 !bg-primary rounded-full flex justify-center items-center cursor-pointer">
                    <IconPlayerPlay className="w-4 h-4 text-white" />
                  </div>
                ) : (
                  <span className="w-7 shrink-0 text-gray-500">
                    {(idx + 1).toString().padStart(2, "0")}
                  </span>
                )}
                <div className="flex w-full items-center  justify-between">
                  <p
                    className={clsx(
                      `lg:text-base md:text-sm  text-sm m-0 py-2 cursor-pointer text-left pl-2 text-gray-900   `,
                      module.passed
                        ? "bg-green-100 group-hover:bg-green-50 hover:bg-green-50"
                        : "bg-white  group-hover:!bg-gray-200 hover:!bg-gray-200"
                    )}
                  >
                    {module.module.title}
                  </p>
                  {module.passed && (
                    <div className="w-5 h-5 bg-green-500 rounded-full flex justify-center items-center">
                      <CheckIcon size={14} color="white" />
                    </div>
                  )}
                </div>
              </div>
            </NavLink>
          ))}
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};

export default MyAccordionCourse;
