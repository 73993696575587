import React from "react";
import { useGetCourseQuery } from "../../slice/resApi/thunk";
import { useDispatch } from "react-redux";
import { fetchData } from "../../slice/courses/fetchData";
import { studentThunk } from "../../slice/student/studentThunk";
import { Spin } from "antd";
import { useEffect } from "react";
import { CourseInfo } from "../../components/course-item/course-info";
import Cookies from "js-cookie";
import AccordionCourse from "../../components/CourseItem/AccordionCourse.tsx";
import CourseCard from "../../components/CourseItem/CourseCard.tsx";

const Course = () => {
  const { data } = useGetCourseQuery();
  const dispatch = useDispatch();
  const token = Cookies.get("token");

  useEffect(() => {
    if (token) {
      dispatch(fetchData());
      dispatch(studentThunk());
    }
  }, []);

  return (
    <div className="flex px-4 md:px-0 lg:justify-center ">
      <div className="flex flex-col gap-6 2xl:w-[80%]  md:w-full pb-6">
        {data ? (
          data?.map((item) => (
            <CourseCard key={item.id} course={item}/>
          ))
        ) : (
          <div className="flex justify-center w-[100%] pt-[10%] pb-[20px]">
            <Spin size="large" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Course;
