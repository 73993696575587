import axios from "axios";
import {
  boughtCourseFailure,
  boughtCourseStart,
  boughtCourseSuccess,
} from "./boughtCourseReducer";
import Cookies from "js-cookie";
import { GLOBAL_URL } from "../../global-url/global-url";

export const boughtCoursesThunk = () => {
  const token = Cookies.get("token");
  return async (dispatch) => {
    try {
      dispatch(boughtCourseStart());
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.get(`${GLOBAL_URL}/api/v1/student/my_courses/`, {
        headers,
      });
      dispatch(boughtCourseSuccess(res.data));
    } catch (e) {
      dispatch(boughtCourseFailure(e.message));
    }
  };
};
