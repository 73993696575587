import { useState } from "react";
import ReactPlayer from "react-player";
import { Card, CardBody } from "reactstrap";
import {
  PlayCircleOutlined,
  QuestionOutlined,
  FolderOpenOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { notesThunk } from "../../slice/notes/notesThunk";
import { Button } from "antd";
import { useEffect } from "react";
import "./styles/courses-completion.css";
import { NavLink } from "react-router-dom";

export const CourseCompletion = (props) => {
  const {
    id,
    title,
    video,
    material,
    note,
    questions,
    number,
    courseID,
    lessonID,
  } = props;
  let [materials, setMaterials] = useState(false);
  let [test, setTest] = useState(false);
  let [video1, setVideo1] = useState(true);
  let [changeVideo, setChangeVideo] = useState(0);
  let [notes, setNotes] = useState("");
  const notice = useSelector((state) => state.notesReducer.notes);

  const dispatch = useDispatch();

  const sendNote = () => {
    let data = {
      courseID,
      lessonID,
      note: notes,
    };
    dispatch(notesThunk({ data }));
    return setNotes(" ");
  };

  useEffect(() => {
    if (number == 1) {
      setMaterials(false);
      setVideo1(video);
      setTest(false);
    }
    if (number == 2) {
      setMaterials(true);
      setVideo1(false);
      setTest(false);
    }
    if (number == 3) {
      setMaterials(false);
      setVideo1(false);
      setTest(true);
    }
  }, []);

  const changePage = () => {
    setMaterials(true);
    setVideo1(false);
    setTest(false);
  };

  const changePage2 = () => {
    setMaterials(false);
    setVideo1(false);
    setTest(true);
  };

  const showVideo = () => {
    setMaterials(false);
    setTest(false);
    setVideo1(true);
  };

  return (
    <div className="w-[100%] flex justify-center pt-[10px]" key={id}>
      <div className="w-[97%]">
        <div className="py-[20px]">
          <h1 className="title-lesson">{title}</h1>
        </div>
        <div className="w-[100%] flex justify-center">
          <div className="switch-material">
            <div className={video1 ? "styleBtn" : "styleBtnTwo"}>
              <button onClick={showVideo}>
                <div className="flex flex-row">
                  <div className="text-2xl">
                    <PlayCircleOutlined />
                  </div>
                  <div className="switch-name">Відео</div>
                </div>
              </button>
            </div>
            <div className=" bg-gray-300 h-[40px] w-[2px] "></div>
            <div className={materials ? "styleBtn" : "styleBtnTwo"}>
              <button onClick={changePage}>
                <div className="flex flex-row">
                  <div className="text-2xl">
                    <FolderOpenOutlined />
                  </div>
                  <div className="switch-name">Матеріал</div>
                </div>
              </button>
            </div>
            <div className=" bg-gray-300 h-[40px] w-[2px] "></div>
            <div className={test ? "styleBtn" : "styleBtnTwo"}>
              <button onClick={changePage2}>
                <div className="flex flex-row">
                  <div className="text-2xl">
                    <QuestionOutlined />
                  </div>
                  <div className="switch-name">Питання</div>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div className="w-[100%]">
          <div className="w-[100%] flex justify-center">
            {materials ? (
              <div className="material-width">
                <Card className="shadow-2xl">
                  <CardBody className="text-start">
                    {material.interesting_links?.map((item) => (
                      <div key={item.id}>
                        <NavLink to={item.link}>
                          <span>{item.name}</span>
                        </NavLink>
                      </div>
                    ))}
                  </CardBody>
                </Card>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="flex pt-[20px] justify-center">
            {video1 ? (
              <div className="size-video">
                <div className="">
                  <div className="flex justify-start w-[100%]">
                    <span className="2xl:text-2xl md:text-base md:h-[40px] sm:h-[60px]">
                      {video[changeVideo].title}
                    </span>
                  </div>
                  <div className="rounded-md w-full flex items-center">
                    <button 
                      onClick={() => {
                        if (changeVideo > 0) setChangeVideo(changeVideo - 1)
                      }}
                      disabled={changeVideo === 0} 
                      className="mr-4 px-4 py-2 h-10 bg-violet-700 text-white transition-all hover:bg-violet-400 rounded-md disabled:bg-violet-200">
                        Prev
                    </button>
                    <div className="height-video w-full">
                      
                      <ReactPlayer
                        url={video[changeVideo].video}
                        width="100%"
                        height="100%"
                        controls="true"
                      />
                     
                    </div>
                    <button 
                      onClick={() => {
                         setChangeVideo(changeVideo + 1)
                      }} 
                      disabled={changeVideo === video.length - 1}
                      className="ml-4 px-4 py-2 h-10 bg-violet-700 text-white transition-all hover:bg-violet-400 rounded-md disabled:bg-violet-200">
                      Next
                    </button>
                  </div>
                </div>
                <div div className="pt-[38px] pb-[20px]">
                  <Card className="shadow-2xl">
                    <CardBody className="flex flex-col ">
                      <div className="py-[10px]">
                        <span className="text-xl font-medium">Нотаток</span>
                        <textarea
                          value={notes}
                          onChange={(e) => setNotes(e.target.value)}
                          className="w-[100%] min-h-[60px] border-1 border-blue-700"
                        />
                        <div className="flex justify-end">
                          <Button
                            onClick={sendNote}
                            type="primary"
                            className="bg-violet-700"
                          >
                            Написати
                          </Button>
                        </div>
                      </div>
                      <div className="text-start md:text-base 2xl:text-base flex flex-col">
                        {notice
                          ? notice?.map((el, i) => (
                              <div
                                className="pl-[10px] text-lg font-normal"
                                key={i}
                              >
                                <span>{el.note}</span>
                              </div>
                            ))
                          : note}
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </div>
            ) : (
              <></>
            )}
            {test ? (
              <div className="questions-width">
                <Card className="w-[100%] min-h-[300px] shadow-2xl ">
                  <CardBody>
                    {questions
                      ? questions.map((item) => (
                          <div
                            className="py-[10px] md:text-2xl sm:text-sm font-medium text-start"
                            key={item.id}
                          >
                            <span>{item.question}</span>
                          </div>
                        ))
                      : null}
                  </CardBody>
                </Card>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
