import { Button as MantineButton } from "@mantine/core";
import clsx from "clsx";
import type { ReactNode } from "react";
import React from "react";

interface ButtonProps {
  fullWidth?: boolean;
  title?: string;
  onClick?: (() => void) | ((e: any) => void);
  isLoading?: boolean;
  type?: "button" | "submit";
  disabled?: boolean;
  classes?: string;
  variant?: "filled" | "outline";
  leftIcon?: ReactNode;
  children?: ReactNode;
}

const Button = (props: ButtonProps) => {
  const {
    fullWidth = false,
    title,
    onClick,
    isLoading = false,
    disabled = false,
    classes = "",
    type = "button",
    variant = "filled",
    leftIcon,
    children,
    ...other
  } = props;

  return (
    <MantineButton
      size="lg"
      disabled={disabled}
      onClick={onClick}
      loading={isLoading}
      fullWidth={fullWidth}
      type={type}
      leftSection={leftIcon}
      classNames={{
        root: clsx(
          "px-4 py-2 rounded-md text-md transition-all ",
          variant === "outline" &&
            "!bg-white hover:!bg-third !border !border-primary !text-primary",
          variant === "filled" && "!bg-primary hover:!bg-secondary !text-white",
          classes
        ),
      }}
      component="button"
      variant={variant}
      {...other}
    >
      {title}
      {children}
    </MantineButton>
  );
};

export default Button;
