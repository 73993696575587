import { FC, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { Card, CardBody } from "reactstrap";
import {
  PlayCircleOutlined,
  QuestionOutlined,
  FolderOpenOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { notesThunk } from "../../slice/notes/notesThunk";
import { NavLink } from "react-router-dom";
import React from "react";
import { Lesson, Quiz } from "../../types/myCourse.type";
import { Tabs, Textarea } from "@mantine/core";
import Button from "../shared/Button.tsx";
import Editor from "../Editor/Editor.tsx";
import clsx from "clsx";
import { IconPlayerPlay } from "@tabler/icons-react";

interface QuizProp {
  id: number;
  passed: boolean;
  quize: Quiz;
}
interface CourseCompletionProps {
  curLesson: Lesson;
  courseId: string;
  quizzes?: QuizProp[];
  moduleId: number;
}

export const CourseCompletion: FC<CourseCompletionProps> = ({
  curLesson,
  courseId,
  moduleId,
  quizzes,
}) => {
  const { id, lesson, note, passed } = curLesson;
  const [activeTab, setActiveTab] = useState<string | null>("video");
  const [selectedVideo, setSelectedVideo] = useState<{
    title: string;
    video: string;
    id: number;
  }>(lesson.video[0]);
  const [notes, setNotes] = useState(note);

  const dispatch = useDispatch();

  const sendNote = () => {
    const data = {
      courseId,
      lessonId: id || 0,
      note: notes,
    };

    dispatch<any>(notesThunk({ data }));
    return setNotes(" ");
  };

  useEffect(() => {
    setSelectedVideo(lesson.video[0]);
  }, [curLesson]);

  return (
    <div className="w-[100%] flex justify-center pt-[10px]">
      <div className="w-[97%]">
        <div className="py-[20px]">
          <h1 className="title-lesson">{lesson?.title}</h1>
        </div>
        <div className="w-[100%] flex justify-center">
          <Tabs
            value={activeTab}
            color="violet"
            onChange={setActiveTab}
            className="w-full flex flex-col items-center "
            classNames={{
              tab: "!focus:border-primary",
            }}
          >
            <Tabs.List className="max-w-2xl">
              <Tabs.Tab value="video">
                <div className="flex justify-center items-center">
                  <div className="text-2xl">
                    <PlayCircleOutlined />
                  </div>
                  <div className="switch-name py-0">Відео</div>
                </div>
              </Tabs.Tab>
              <Tabs.Tab value="materials">
                <div className="flex justify-center items-center">
                  <div className="text-2xl">
                    <FolderOpenOutlined />
                  </div>
                  <div className="switch-name py-0">Матеріал</div>
                </div>
              </Tabs.Tab>
              <Tabs.Tab value="questions">
                <div className="flex justify-center items-center">
                  <div className="text-2xl">
                    <QuestionOutlined />
                  </div>
                  <div className="switch-name py-0">Питання</div>
                </div>
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel
              value="video"
              className="w-full flex justify-center mt-10"
            >
              <div className="size-video flex">
                <div className="flex flex-col w-full">
                  <div className="">
                    <div className="flex justify-start w-[100%]">
                      <span className="2xl:text-2xl md:text-base md:h-[40px] sm:h-[60px] font-medium">
                        {selectedVideo?.title}
                      </span>
                    </div>
                    <div className="rounded-md w-full flex lg:flex-row flex-col  ">
                      <div className="height-video w-full flex ">
                        <ReactPlayer
                          url={selectedVideo?.video}
                          width="100%"
                          height="100%"
                          controls={true}
                        />
                      </div>
                      <div className="lg:ml-4 ml:0 lg:!mt-0 mt-4 lg:w-72 w-full !border lg:!h-[500px] h-[300px] overflow-y-auto !text-left rounded-md !border-primary ">
                        {lesson.video.length > 0 &&
                          lesson.video.map((video, idx) => (
                            <button
                              key={video.id}
                              onClick={() => setSelectedVideo(video)}
                              title={video.title}
                              className={clsx(
                                "w-full text-base flex items-center justify-start p-2 gap-2  transition-all duration-300 hover:!bg-secondary hover:text-white  hover:shadow-2xl",
                                video.id === selectedVideo?.id &&
                                  "!bg-secondary text-white",
                                idx === 0 && "rounded-t-sm"
                              )}
                            >
                              <div className="w-7 h-7 !bg-primary rounded-full flex shrink-0 justify-center items-center cursor-pointer">
                                <IconPlayerPlay className="w-4 h-4 text-white " />
                              </div>
                              <span className="!truncate !p-0">
                                {video.title}
                              </span>
                            </button>
                          ))}
                        {quizzes &&
                          quizzes.length > 0 &&
                          quizzes?.map(({ quize }) => (
                            <NavLink
                              key={quize?.id}
                              to={`/question/${courseId}/${moduleId}/${quize?.id}`}
                              title={quize?.title}
                              className={clsx(
                                "w-full text-base text-gray-900 no-underline flex items-center justify-start p-2 gap-2  transition-all duration-300 hover:!bg-secondary hover:text-white  hover:shadow-2xl"
                              )}
                            >
                              <div className="w-7 h-7 !bg-primary rounded-full flex shrink-0 justify-center items-center cursor-pointer">
                                <IconPlayerPlay className="w-4 h-4 text-white " />
                              </div>
                              <span className="!truncate !p-0">
                                Тест {quize?.title}
                              </span>
                            </NavLink>
                          ))}
                      </div>
                    </div>
                  </div>
                  <div className="pt-[38px] pb-[20px] ">
                    <Card className="shadow-2xl">
                      <CardBody className="flex flex-col ">
                        <div className="py-[10px]">
                          <span className="text-xl font-medium">Нотаток</span>
                          <Editor
                            initialContent={notes}
                            setInitialContent={(value) => setNotes(value)}
                          />
                          <div className="flex justify-end pt-5">
                            <Button
                              onClick={sendNote}
                              classes="w-[140px] h-[40px] text-base"
                              title="Написати"
                            ></Button>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </div>
              </div>
            </Tabs.Panel>
            <Tabs.Panel
              value="materials"
              className="w-full flex justify-center"
            >
              {lesson.material?.interesting_links.length > 0 ? (
                <div className="material-width">
                  <Card className="shadow-2xl">
                    <CardBody className="text-start">
                      {lesson.material.interesting_links?.map((item) => (
                        <div key={item?.id}>
                          <NavLink to={item?.link} target="_blank">
                            <span>{item?.name}</span>
                          </NavLink>
                        </div>
                      ))}
                    </CardBody>
                  </Card>
                </div>
              ) : (
                <div className="w-full h-full flex justify-center items-center font-medium  text-2xl mt-4">
                  Матеріали відсутні!
                </div>
              )}
            </Tabs.Panel>
            <Tabs.Panel
              value="questions"
              className="w-full flex justify-center"
            >
              {lesson.questions.length > 0 ? (
                <div className="questions-width">
                  <Card className="w-[100%] min-h-[300px] shadow-2xl ">
                    <CardBody>
                      {lesson.questions
                        ? lesson.questions.map((item) => (
                            <div
                              className="py-[10px] md:text-2xl sm:text-sm font-medium text-start"
                              key={item.id}
                            >
                              <span>{item.question}</span>
                            </div>
                          ))
                        : null}
                      {}
                    </CardBody>
                  </Card>
                </div>
              ) : (
                <div className="w-full h-full flex justify-center items-center font-medium  text-2xl mt-4">
                  Питання відсутні!
                </div>
              )}
            </Tabs.Panel>
          </Tabs>
        </div>
      </div>
    </div>
  );
};
