import axios from "axios";
import { notesFailure, notesStart, notesSuccess } from "./notesReducer";
import Cookies from "js-cookie";
import { GLOBAL_URL } from "../global-url/global-url";

export const notesThunk = (props) => {
  const { data } = props;
  const token = Cookies.get("token");

  let formData = new FormData();
  formData.append("note", data.note);

  return async (dispatch) => {
    try {
      dispatch(notesStart());
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.put(
        `${GLOBAL_URL}/api/v1/student/my_courses/${data.courseId}/my_lesson/${data.lessonId}/note/`,
        formData,
        {
          headers,
        }
      );
      let array = [res.data];
      dispatch(notesSuccess(array));
    } catch (e) {
      dispatch(notesFailure(e.message));
    }
  };
};
