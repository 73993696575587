import { Text, Accordion, Avatar } from "@mantine/core";
import { Course } from "../../types/course.type";
import { FC, useState } from "react";
import formatString from "../../utils/formatString.ts";
import { IconClockHour4 } from "@tabler/icons-react";
import React from "react";

interface AccordionCourseProps {
  course: Course;
}

interface AccordionLabelProps {
  course: Course;
}

const AccordionLabel = ({ course }: AccordionLabelProps) => {
  const { image, title, description, speaker } = course;
  return (
    <div className="flex items-center md:items-center lg:flex-row flex-col w-full lg:items-center xl:items-start relative">
      <img
        src={image}
        alt={title}
        className="sm:h-32 sm:w-32 xl:w-64 xl:h-64 lg:w-48 lg:h-48 md:w-48 md:h-48 rounded-md md:mb-4 "
      />
      <div className="flex flex-col gap-2 w-full">
        <h1 className="self-center text-center font-semibold text-purple-950 text-xl pt-2 lg:pt-0 md:text-2xl lg:mr-[40px]">
          {title}
        </h1>
        <div
          title={speaker.name}
          className="hidden absolute top-[-5px] right-0 md:flex flex-col justify-center items-center"
        >
          <Avatar
            classNames={{
              image: "bg-contain! bg-no-repeat! bg-center!",
            }}
            variant="outline"
            src={speaker.photo}
            color="cyan"
            radius="sm"
            size="lg"
          />
          <p className="text-gray-800">{speaker.name.split(" ")[0]}</p>
        </div>
        <div className="sm:px-0  md:px-4 pt-2  xl:max-w-xl lg:max-w-sm h-full lg:min-h-[110px] xl:min-h-[170px]">
          {formatString(description).map((str, idx) => (
            <Text
              className="xl:!text-base lg:text-sm text-sm text-gray-800"
              key={idx}
            >
              {str}
            </Text>
          ))}
        </div>
        <div className="w-full place-self-end flex justify-end">
          <div className="flex gap-3">
            <IconClockHour4 />
            {course.duration}
          </div>
        </div>
      </div>
    </div>
  );
};

const AccordionCourse: FC<AccordionCourseProps> = ({ course }) => {
  const [isOpened, setIsOpened] = useState<string | null>(null);
  return (
    <Accordion
      value={isOpened}
      onChange={setIsOpened}
      classNames={{
        item: `border-[2px]  hover:!border-primary !bg-opacity-[0.2] !transition-all ${
          isOpened
            ? "!bg-third !border-primary !border-b-0"
            : "bg-white! !border-white"
        }`,
        chevron: "!hidden",
        panel: `${isOpened && " bg-white"}`,
        icon: `${isOpened && "fill-white"}`,
        content: "",
      }}
      variant="contained"
    >
      <Accordion.Item value={course.title} key={course.id}>
        <Accordion.Control className="w-full">
          <AccordionLabel course={course} />
        </Accordion.Control>
        <Accordion.Panel>
          {course.modules.map((module, idx) => (
            <div
              className={`flex w-full lg:px-4 md:px-2 items-center border-b `}
              key={module.id}
            >
              <span className="w-7 shrink-0 text-gray-500">
                {(idx + 1).toString().padStart(2, "0")}
              </span>
              <p
                className={`lg:text-base md:text-sm text-sm m-0 py-2 text-left pl-2 text-gray-900 bg-white  `}
              >
                {module.title}
              </p>
            </div>
          ))}
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};

export default AccordionCourse;
