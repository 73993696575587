import { useState } from "react";
import logo from "../../assets/logo.png";
import {
  AppstoreOutlined,
  CalendarOutlined,
  LinkOutlined,
  BarsOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { fetchData } from "../../slice/courses/fetchData";
import { studentThunk } from "../../slice/student/studentThunk";
import { useDispatch, useSelector } from "react-redux";
import { MyCourcesDropdown } from "./dropdown/my-courses-dropdown";
import { boughtCoursesThunk } from "../../slice/my-courses/boughtCourses/boughtCoursesThunk";
import "./styles/navbar.css";

export const Navbar = (props) => {
  const { showMenu, handleMenu, handleMenuMobile } = props;
  const [course, setCourse] = useState(false);
  const [myCourse, setMyCourse] = useState(false);
  const [calendar, setCalendar] = useState(false);
  const [job, setJob] = useState(false);

  const [showTrojan, setShowTrojan] = useState(false);

  const [showMyCourses, setShowMyCourses] = useState(false);
  const [showMyModule, setShowMyModule] = useState(false);

  const { isLoading, myBoughtCourses, error } = useSelector(
    (state) => state.boughtCoursesReducer
  );

  const dispatch = useDispatch();

  const token = Cookies.get("token");

  useEffect(() => {
    if (token ) {
      dispatch(fetchData());
      dispatch(studentThunk());
      dispatch(boughtCoursesThunk());
    }
  }, []);

  const handleCourse = () => {
    setCourse(true);
    setMyCourse(false);
    setCalendar(false);
    setJob(false);
  };

  const handleMyCourse = () => {
    setCourse(false);
    setMyCourse(true);
    setCalendar(false);
    setJob(false);
  };

  const handleCalendar = () => {
    setCourse(false);
    setMyCourse(false);
    setCalendar(true);
    setJob(false);
  };

  const handleJob = () => {
    setCourse(false);
    setMyCourse(false);
    setCalendar(false);
    setJob(true);
  };

  const trojan = () => {
    setShowTrojan((prev) => !showTrojan);
  };

  const handleMouseEnter = () => {
    setShowMyCourses(true);
  };

  const handleMouseLeave = () => {
    setShowMyCourses(false);
  };

  const handleMouseLeaveCource = () => {
    setShowMyModule(false);
  };

  return (
    <div>
      <div className="for-mobile">
        <div className="navbar-logo">
          <img src={logo} alt="" className="navbar-logo-width" />
        </div>
        <div className="close-menu" onClick={handleMenuMobile}>
          <CloseOutlined />
        </div>
      </div>

      <div className="for-desktop">
        <div className="navbar-logo">
          <img src={logo} alt="" className="navbar-logo-width" />
        </div>
      </div>
      <>
        {token ? (
          <div className="pt-[20px]">
            {showMenu ? (
              <div onMouseLeave={handleMouseLeave}>
                <div className="text-trojan">Trojan</div>
                <div className="nav-for-mobile">
                  <div className={course ? "styleforIcon" : "styleforIconTwo"}>
                    <span>
                      <AppstoreOutlined />
                    </span>
                  </div>
                  <div className="for-space">
                    <div className="flex flex-row">
                      <NavLink
                        to="/"
                        className={course ? "styleForText" : "styleForTextTwo"}
                        onClick={() => {
                          handleCourse(), handleMenuMobile();
                        }}
                      >
                        Всі курси
                      </NavLink>
                      <div
                        className="pl-[7px] text-xs pt-[3px]"
                        onClick={trojan}
                      ></div>
                    </div>
                  </div>
                </div>

                <div className="nav-for-desktop" onClick={() => handleCourse()}>
                  <div className={course ? "styleforIcon" : "styleforIconTwo"}>
                    <span>
                      <AppstoreOutlined />
                    </span>
                  </div>
                  <div className="for-space">
                    <div className="flex flex-row">
                      <NavLink
                        to="/"
                        className={course ? "styleForText" : "styleForTextTwo"}
                      >
                        Всі курси
                      </NavLink>
                      <div
                        className="pl-[7px] text-xs pt-[3px]"
                        onClick={trojan}
                      ></div>
                    </div>
                  </div>
                </div>

                <div className="nav-for-mobile">
                  <div
                    className={myCourse ? "styleforIcon" : "styleforIconTwo"}
                  >
                    <span>
                      <BarsOutlined />
                    </span>
                  </div>
                  <div className="my-courses-for-mobile">
                    <div className="for-space">
                      <div className="flex flex-row">
                        <NavLink
                          to="/myCourses"
                          className={
                            myCourse ? "styleForText" : "styleForTextTwo"
                          }
                          onClick={() => {
                            handleMyCourse(), handleMenuMobile();
                          }}
                        >
                          Мої курси
                        </NavLink>
                        <div className="pl-[7px] text-xs pt-[3px]"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="nav-for-desktop"
                  onClick={() => handleMyCourse()}
                >
                  <div
                    className={myCourse ? "styleforIcon" : "styleforIconTwo"}
                  >
                    <span>
                      <BarsOutlined />
                    </span>
                  </div>
                  <div className="my-courses-for-desktop">
                    <div className="for-space">
                      <div
                        className="flex flex-row"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeaveCource}
                      >
                        <NavLink
                          to="/myCourses"
                          className={
                            myCourse ? "styleForText" : "styleForTextTwo"
                          }
                        >
                          Мої курси
                        </NavLink>
                        <div className="pl-[7px] text-xs pt-[3px]">
                          {showMyCourses ? (
                            <div className="w-[15px] h-[15px] border-1 border-black cursor-pointer rounded-full">
                              <div className="-mt-[2px] text-xs">
                                <DownOutlined />
                              </div>
                            </div>
                          ) : (
                            <div className="w-[15px] h-[15px] border-1 border-black cursor-pointer rounded-full">
                              <div className="-mt-[4px]">
                                <UpOutlined />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      {showMyCourses ? (
                        <>
                          {myBoughtCourses ? (
                            myBoughtCourses?.map((item) => (
                              <MyCourcesDropdown
                                key={item.id}
                                courseID={item.id}
                                courcesTitle={item.course.title}
                                courseModules={item.course.modules}
                                setShowMyModule={setShowMyModule}
                                showMyModule={showMyModule}
                              />
                            ))
                          ) : (
                            <div>
                              <span className="text-sm">
                                немає куплених курсів
                              </span>
                            </div>
                          )}
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="nav-for-mobile">
                  <div
                    className={calendar ? "styleforIcon" : "styleforIconTwo"}
                  >
                    <span>
                      <CalendarOutlined />
                    </span>
                  </div>
                  <div className="for-space">
                    <NavLink
                      to="/calendar"
                      className={calendar ? "styleForText" : "styleForTextTwo"}
                      onClick={() => {
                        handleCalendar(), handleMenuMobile();
                      }}
                    >
                      Календар
                    </NavLink>
                  </div>
                </div>

                <div
                  className="nav-for-desktop"
                  onClick={() => handleCalendar()}
                >
                  <div
                    className={calendar ? "styleforIcon" : "styleforIconTwo"}
                  >
                    <span>
                      <CalendarOutlined />
                    </span>
                  </div>
                  <div className="for-space">
                    <NavLink
                      to="/calendar"
                      className={calendar ? "styleForText" : "styleForTextTwo"}
                    >
                      Календар
                    </NavLink>
                  </div>
                </div>

                <div className="nav-for-mobile">
                  <div className={job ? "styleforIcon" : "styleforIconTwo"}>
                    <span>
                      <LinkOutlined />
                    </span>
                  </div>
                  <div className="for-space">
                    <NavLink
                      to="/job"
                      className={job ? "styleForText" : "styleForTextTwo"}
                      onClick={() => {
                        handleJob(), handleMenuMobile();
                      }}
                    >
                      Вакансії
                    </NavLink>
                  </div>
                </div>

                <div className="nav-for-desktop" onClick={() => handleJob()}>
                  <div className={job ? "styleforIcon" : "styleforIconTwo"}>
                    <span>
                      <LinkOutlined />
                    </span>
                  </div>
                  <div className="for-space">
                    <NavLink
                      to="/job"
                      className={job ? "styleForText" : "styleForTextTwo"}
                    >
                      Вакансії
                    </NavLink>
                  </div>
                </div>
              </div>
            ) : (
              <div className="pt-[20px]">
                <div className="pb-[10px]" onClick={() => handleCourse()}>
                  <NavLink
                    to="/"
                    className={course ? "styleforIcon" : "styleforIconTwo"}
                  >
                    <AppstoreOutlined />
                  </NavLink>
                </div>
                <div className="pb-[10px]" onClick={() => handleMyCourse()}>
                  <NavLink
                    to="/myCourses"
                    className={myCourse ? "styleforIcon" : "styleforIconTwo"}
                  >
                    <BarsOutlined />
                  </NavLink>
                </div>
                <div className="pb-[10px]" onClick={() => handleCalendar()}>
                  <NavLink
                    to="/calendar"
                    className={calendar ? "styleforIcon" : "styleforIconTwo"}
                  >
                    <CalendarOutlined />
                  </NavLink>
                </div>
                <div onClick={() => handleJob()}>
                  <NavLink
                    to="/job"
                    className={job ? "styleforIcon" : "styleforIconTwo"}
                  >
                    <LinkOutlined />
                  </NavLink>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="pt-[20px]">
            {showMenu ? (
              <div>
                <div className="text-lg text-black font-medium text-start pl-[25px] pb-[10px]">
                  Trojan
                </div>

                <div className="flex flex-row pl-[25px] py-[10px]">
                  <div className={course ? "styleforIcon" : "styleforIconTwo"}>
                    <span>
                      <AppstoreOutlined />
                    </span>
                  </div>
                  <div className="for-space">
                    <div className="flex flex-row">
                      <div className="nav-for-mobile-input">
                        <NavLink
                          to="/"
                          className={
                            course ? "styleForText" : "styleForTextTwo"
                          }
                          onClick={() => {
                            handleCourse(), handleMenuMobile();
                          }}
                        >
                          Всі курси
                        </NavLink>
                      </div>
                      <div className="nav-for-desktop-input">
                        <NavLink
                          to="/"
                          className={
                            course ? "styleForText" : "styleForTextTwo"
                          }
                          onClick={() => {
                            handleCourse();
                          }}
                        >
                          Всі курси
                        </NavLink>
                      </div>
                      <div
                        className="pl-[5px] text-xs pt-[3px]"
                        onClick={trojan}
                      ></div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-row pl-[25px] pb-[10px]">
                  <div
                    className={calendar ? "styleforIcon" : "styleforIconTwo"}
                  >
                    <span>
                      <CalendarOutlined />
                    </span>
                  </div>
                  <div className="for-space">
                    <div className="nav-for-mobile-input">
                      <NavLink
                        to="/calendar"
                        className={
                          calendar ? "styleForText" : "styleForTextTwo"
                        }
                        onClick={() => {
                          handleCalendar(), handleMenuMobile();
                        }}
                      >
                        Календар
                      </NavLink>
                    </div>
                    <div className="nav-for-desktop-input">
                      <NavLink
                        to="/calendar"
                        className={
                          calendar ? "styleForText" : "styleForTextTwo"
                        }
                        onClick={() => {
                          handleCalendar();
                        }}
                      >
                        Календар
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row pl-[25px]">
                  <div className={job ? "styleforIcon" : "styleforIconTwo"}>
                    <span>
                      <LinkOutlined />
                    </span>
                  </div>
                  <div className="for-space">
                    <div className="nav-for-mobile-input">
                      <NavLink
                        to="/job"
                        className={job ? "styleForText" : "styleForTextTwo"}
                        onClick={() => {
                          handleJob(), handleMenuMobile();
                        }}
                      >
                        Вакансії
                      </NavLink>
                    </div>
                    <div className="nav-for-desktop-input">
                      <NavLink
                        to="/job"
                        className={job ? "styleForText" : "styleForTextTwo"}
                        onClick={() => {
                          handleJob();
                        }}
                      >
                        Вакансії
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="pt-[20px]">
                <div className="pb-[10px] " onClick={() => handleCourse()}>
                  <NavLink
                    to="/"
                    className={course ? "styleforIcon" : "styleforIconTwo"}
                  >
                    <AppstoreOutlined />
                  </NavLink>
                </div>
                <div className="pb-[10px]" onClick={() => handleCalendar()}>
                  <NavLink
                    to="/calendar"
                    className={calendar ? "styleforIcon" : "styleforIconTwo"}
                  >
                    <CalendarOutlined />
                  </NavLink>
                </div>
                <div onClick={() => handleJob()}>
                  <NavLink
                    to="/job"
                    className={job ? "styleforIcon" : "styleforIconTwo"}
                  >
                    <LinkOutlined />
                  </NavLink>
                </div>
              </div>
            )}
          </div>
        )}
      </>
    </div>
  );
};
