import "./App.css";
import Course from "./pages/course/course";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Profile from "./pages/profile/profile";
import { Calendars } from "./pages/calendar/calendar";
import JobList from "./pages/job";
import { MyCourses } from "./pages/my-courses/my-courses";
import { Header } from "./components/header/header";
import { Login } from "./pages/login/login";
import { LessonsData } from "./components/my-courses-item/lessons-data";
import { Register } from "./pages/register/register";
import { Certificates } from "./pages/certificates/certificates";
import { CourseInfo } from "./components/course-item/course-info";
import { FAQ } from "./pages/faq/faq";
import { useState } from "react";
import { ResultAnswers } from "./components/my-courses-item/resultAnswers";
import { Navbar } from "./components/navbar/navbar";
import { useSelector } from "react-redux";
import { NotFound } from "./pages/not-found/not-found";
import { LessonsDataModule } from "./components/my-courses-item/lessons-data-module";
import { Question } from "./components/my-courses-item/question";
import Cookies from "js-cookie";
import { ResetPassword } from "./pages/resetPassword/resetPassword";
import { PrivacyPolicy } from "./pages/privacy-policy/privacy-policy";

function App() {
  let [showMenu, setShowMenu] = useState(true);
  let [showMenuMobile, setShowMenuMobile] = useState(false);
  const { email, error } = useSelector((state) => state.registerReducer);
  const token = Cookies.get("token");

  const handleMenu = () => {
    setShowMenu((prev) => !showMenu);
    setShowMenuMobile((prev) => !showMenuMobile);
  };

  const handleMenuMobile = () => {
    setShowMenuMobile(false);
    setShowMenuMobile((prev) => !showMenuMobile);
  };

  return (
    <BrowserRouter>
      <div className="App flex flex-row w-[100%] min-h-screen">
        <div className="min-h-max">
          <div className="show-menu-for-desktop">
            <div className={showMenu ? "show" : "hide"}>
              <Navbar
                showMenu={showMenu}
                handleMenu={handleMenu}
              />
            </div>
          </div>
        </div>

        <div className="show-menu-for-mobile">
          <div className="min-h-max">
            <div className={showMenuMobile ? "show" : "hide"}>
              <Navbar
                showMenu={showMenu}
                handleMenu={handleMenuMobile}
                handleMenuMobile={handleMenuMobile}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col  w-[100%]">
          <div className="w-[100%]">
            <Header
              handleMenu={handleMenu}
              handleMenuMobile={handleMenuMobile}
            />
          </div>
          <div>
            <Routes>
              <Route path={"/login"} element={<Login />} />
              <Route
                path={"/register"}
                element={<Register data={email} error={error} />}
              />
              <Route
                path={"/resetPassword/:uidb64/:token/"}
                element={<ResetPassword />}
              />
              <Route path={"/"} element={<Course />} />
              <Route path={"/result"} element={<ResultAnswers />} />
              <Route path={"/courseInfo/:id"} element={<CourseInfo />} />
              <Route path={"/myCourses"} element={<MyCourses />} />
              <Route
                path={"/lessonsData/:moduleID/:lessonID/:number"}
                element={<LessonsData />}
              />
              <Route
                path={"/question/:courseID/:moduleID/:questionID"}
                element={<Question />}
              />
              <Route
                path={"/lessonsData/:id/"}
                element={<LessonsDataModule />}
              />
              <Route path={"/profile"} element={<Profile />} />
              <Route path={"/certificates"} element={<Certificates />} />
              <Route path={"/calendar"} element={<Calendars />} />
              <Route path={"/job"} element={<JobList />} />
              <Route path={"/FAQ"} element={<FAQ />} />
              <Route path={"/privacy"} element={<PrivacyPolicy />} />
              <Route path={"*"} element={<NotFound />} />
            </Routes>
          </div>
          {token && (
            <div className="position-fixed right-1 bottom-2 z-40">
              <FAQ />
            </div>
          )}
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
