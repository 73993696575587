import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Spin } from "antd";
import { Breadcrumb } from "antd";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Cookies from "js-cookie";
import { fetchData } from "../../slice/courses/fetchData";
import { studentThunk } from "../../slice/student/studentThunk";
import { boughtCoursesThunk } from "../../slice/my-courses/boughtCourses/boughtCoursesThunk";
import { CourseCompletion } from "../CourseCompletion/CourseCompletion.tsx";
import { IconArrowLeft } from "@tabler/icons-react";
import { IconArrowRight } from "@tabler/icons-react";

export const LessonsDataModule = () => {
  const { id } = useParams();

  const [currentLesson, setCurrentLesson] = useState(0);
  const [myLessons, setMyLessons] = useState(null);
  const [courseID, setCourseID] = useState();

  const { isLoading, myBoughtCourses, error } = useSelector(
    (state) => state.boughtCoursesReducer
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = Cookies.get("token");

  useEffect(() => {
    if (token) {
      dispatch(fetchData());
      dispatch(studentThunk());
      dispatch(boughtCoursesThunk());
    }
    if (!token) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (myBoughtCourses) {
      myBoughtCourses.map((item) => {
        item.course.modules.map((el) => {
          if (el.module.id == id) {
            setCourseID(item.id);
            setMyLessons(el.module.lessons);
          }
        });
      });
    }
  }, [myBoughtCourses, id]);

  const changePage = (action) => {
    const currentCourse = myBoughtCourses.find((item) => item.id === courseID)
    let index = 0
    currentCourse.course.modules.forEach((item, i) => {
        if (item.module.id == id) {
          index = i
        }
    })

    if (action === 'next') {
      navigate(`/lessonsData/${currentCourse.course.modules[index + 1].module.id}`)
    } else {
      navigate(`/lessonsData/${currentCourse.course.modules[index - 1].module.id}`)
    }  
  }

  return (
    <div className="flex flex-col w-[100%]">
      <div>
        <div className="pl-[10px]">
          <Breadcrumb
            items={[
              {
                title: (
                  <NavLink to="/myCourses" className="no-underline">
                    Мої курси
                  </NavLink>
                ),
              },
              {
                title: (
                  <span>
                    {myLessons ? myLessons[currentLesson]?.lesson.title : ""}
                  </span>
                ),
              },
            ]}
          />
        </div>
        <hr />
      </div>
      {isLoading && !myBoughtCourses && (
        <div className="flex justify-center w-[100%] pt-[10%] pb-[20px]">
          <Spin size="large" />
        </div>
      )}
      {myLessons && (
        <div className="flex flex-col w-[100%] pt-[10px]">
          <div className="flex justify-between">
            <button
              disabled={
                myBoughtCourses
                  .find((item) => item.id === courseID)
                  ?.course.modules.find((item) => item.module.id === Number(id)).module.id === 
                  myBoughtCourses
                    .find((item) => item.id === courseID)
                    ?.course.modules[0].module.id
              }
              onClick={() => {
                changePage('previous')
              }}
              className="ml-5 flex justify-center p-2 rounded-full items-center text-3xl text-white !bg-primary hover:!bg-secondary cursor-pointer transition-all disabled:!bg-secondary"
            >
              <IconArrowLeft />
            </button>
            <button
              disabled={
                myBoughtCourses
                  .find((item) => item.id === courseID)
                  ?.course.modules.find((item) => item.module.id === Number(id)).module.id === 
                  myBoughtCourses
                    .find((item) => item.id === courseID)
                    ?.course.modules[
                      myBoughtCourses
                        .find((item) => item.id === courseID)
                        ?.course.modules.length - 1
                    ].module.id
              }
              onClick={() => {
                changePage('next')
              }}
              className="mr-5 flex justify-center p-2 rounded-full items-center text-3xl text-white !bg-primary hover:!bg-secondary cursor-pointer transition-all disabled:!bg-secondary"
            >
              <IconArrowRight />
            </button>
          </div>
          <div>
            {myLessons[currentLesson] && <CourseCompletion
              courseId={courseID}
              curLesson={myLessons[currentLesson]}
              quizzes={myBoughtCourses
                ?.find((item) => item.id === courseID)
                ?.course.modules?.find((item) => item.module.id === Number(id))?.module?.quizzes}
              moduleId={myBoughtCourses
                .find((item) => item.id === courseID)
                ?.course.modules.find((item) => item.module.id === Number(id))?.module?.id}
            />}
          </div>
        </div>
      )}
      {error && (
        <div className="flex justify-center w-[100%] pt-[10%]">
          <h4>{error}</h4>
        </div>
      )}
    </div>
  );
};
