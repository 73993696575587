import { FC } from "react";
import AccordionCourse from "./AccordionCourse.tsx";
import { Course } from "../../types/course.type.tsx";
import Button from "../shared/Button.tsx";
import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

interface CourseCardProps {
  course: Course;
}

const CourseCard: FC<CourseCardProps> = ({ course }) => {
  const { price } = course;
  const student = useSelector((state: any) => state.studentReducer.student);
  const start = "https://secure.wayforpay.com/button/b2c2fd7bff3d1";
  const pro = "https://secure.wayforpay.com/button/b7a48d02644dd";

  return (
    <div className="w-full max-w-[1440px] bg-white p-4  border rounded-xl shadow-lg ">
      <AccordionCourse course={course} />
      <div className="w-full flex justify-between items-center  pt-4">
        <div className=" text-xl text-purple-950 flex font-semibold items-center">
          {price} ₴
        </div>
        {student ? (
          <NavLink to={course.id === 1 ? start : pro} target="_blank">
            <Button
              classes="lg:!text-base xl:!text-lg animate-pulse"
              title="Купити"
              variant="filled"
            />
          </NavLink>
        ) : (
          <NavLink to="/login">
            <Button
              classes="lg:!text-base xl:!text-lg animate-pulse"
              title="Купити"
              variant="filled"
            />
          </NavLink>
        )}
      </div>
    </div>
  );
};

export default CourseCard;
